<template>
  <div class="main">
    <el-container>
      <el-header
        >ACCUonMe表單系統 v2 管理頁面 <span v-if="isDev">(開發用) </span>( v{{
          version
        }}
        )</el-header
      >
      <el-main>
        <el-menu :default-active="$route.path" mode="horizontal" router>
          <el-menu-item index="/admin">表單追蹤清單</el-menu-item>
          <el-menu-item index="/admin/old-form"
            >表單追蹤清單 (舊系統)</el-menu-item
          >
          <el-menu-item index="/admin/order">訂單</el-menu-item>
          <el-submenu index="/admin/setting">
            <template slot="title">設定</template>
            <el-menu-item index="/admin/bundle">套組</el-menu-item>
            <el-menu-item index="/admin/testing">檢測項目</el-menu-item>
            <el-menu-item index="/admin/client">廠商</el-menu-item>
            <el-menu-item index="/admin/form-template">表單範本</el-menu-item>
            <el-menu-item index="/admin/default">轉移資料</el-menu-item>
          </el-submenu>
        </el-menu>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "AdminView",

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      isDev: process.env.NODE_ENV === "development",
    };
  },
};
</script>
<style scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center; */
  /* line-height: 160px; */
}
.el-cascader {
  width: 300px;
}
.tool {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* /deep/ .search .el-dialog__header {
  padding: 0;
} */
</style>
